<template>
    <main class="main">
        <MyHeader />
        <section class="detail">
            <div class="wrap">
                <h2 v-if="getdata || corr" class="section-title">
                    <li>{{getData.title_peak || corr.title_peak}}, {{getData.title_lncrna || corr.title_lncrna}}</li>
                    <!-- <li><a>{{getData.title_lncrna || corr.title_lncrna}}</a></li> -->
                </h2>
                <h4>{{getData.title || corr.title}}</h4>
                <div class="block">
                  <div class="block-wrap" v-if="getData || corr">
                      <h4>{{corr.chart.title}}</h4>
                      <ChartCombo :data="getData.chart || corr.chart" />
                  </div>
                  <div class="block-none" v-if="!getData  && !this.$store.getters.loader">
                      <h4>There is no data</h4>
                  </div>
                </div>


                <Links v-if="corr" :data="corr.links" />
                <MyTable class="table-wrap_long" :max="10" title="Data by selected Correlation" v-if="getData || corr" :data="getData.table || corr.table" />
                <Loader />
            </div>
        </section>
        <MyFooter />
    </main>
</template>
<script>
import MyHeader from '@/components/MyHeader';
import MyFooter from '@/components/MyFooter';
import MyTable from '@/components/MyTable';
import Links from '@/components/Links';
import Loader from '@/components/Loader';
import ChartCombo from '@/components/charts/ChartCombo'

export default {
  name: 'lncrna',
  components: {
    MyHeader,
    MyTable,
    Links,
    MyFooter,
    Loader,
    ChartCombo
  },
  data() {
    return {
      corr: null,
      showDropdown: true
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    }
  },
  computed: {
    getData () {
      return this.$store.getters.getCorr
    }
  },
  async mounted () {
    this.$store.dispatch('setLoader', true)
    this.corr = await this.$store.dispatch('fetchCorr', [this.$route.query, 1, 10])
    this.$store.dispatch('setLoader', false)
  }
}

</script>


<style>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  list-style-type: none;
  display: none;
}

.dropdown-menu li {
  margin-bottom: 5px;
}

.dropdown-menu li a {
  text-decoration: none;
  color: #333;
}
</style>